<template>
  <HomeContainer>
    <template v-slot:main>
      <div class="not-found">
        <img src="@/assets/img/404.png" class="countdown container col-10 col-sm-3"/>
        <button class="btn btn-danger text-button col-10 col-sm-2" @click="returnHome">Voltar para a home</button>
      </div>
    </template>
    <template v-slot:footer>
      <Footer class="footer"/>
    </template>
  </HomeContainer>
</template>

<script>

import Footer from '@/components/Footer/Footer.vue';
import HomeContainer from "@/components/Container/HomeContainer.vue";

export default {
  name: 'NotFound',
  components: {
    Footer,
    HomeContainer
  },
  methods: {
    returnHome() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.text-button {
  font-size: 0.8rem;
  margin-top: 2rem;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.countdown {
  margin-top: 6rem;
}

.not-found-text {
  font-size: 0.9rem;
  color: #dc3545;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  font-family: 'Roboto', sans-serif;
}

.footer {
  background-color: #000;
  color: #fff;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
