<template>
    <div class="grid-page">
        <slot name="header"></slot>
        <slot name="adressbar"></slot>
        <div class="main-content">
            <slot name="main"></slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: "HomeContainer",
    };
</script>
